import {GetManageLicenseUser} from './../../e-sarabun/system/permission-user/permission-user-model';
import {Injectable} from '@angular/core';
import {HttpService} from './http.service';
import {Observable, of} from 'rxjs';
import {LoggerService} from './logger.service';
import {ClaimModel, DataCarImg, UserProfile} from '../model/model';
import {AuthService} from './auth.service';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {environment} from '../../../environments/environment';
import {HttpBackend, HttpClient, HttpParams} from '@angular/common/http';
import {base64ToFile} from './utils/blob.utils';
import {Router} from '@angular/router';


// const logger = new LoggerService('ApiService');

@Injectable({
    providedIn: 'root'
})
export class ApiMeetingService {
    Api_oae_Meeting = environment.Api_oae_Meeting;
    API_SERVER_ADDRESS = environment.API_SERVER_ADDRESS;
    private httpBackend: HttpClient;
    token: any = '';

    constructor(
        public http: HttpService,
        public auth: AuthService,
        public sanitizer: DomSanitizer,
        handler: HttpBackend,
        private router: Router
    ) {
        this.httpBackend = new HttpClient(handler);
    }

    GetAllTypeBook(): Promise<any> {
        return this.http.get(this.Api_oae_Meeting + 'emeeting/Get_AllTypeBook').toPromise();
    }
    GetAllGroupMeeting(): Promise<any> {
        return this.http.get(this.Api_oae_Meeting + 'emeeting/Get_AllGroupMeeting').toPromise();
    }
    GetAllPlaceMeeting(): Promise<any> {
        return this.http.get(this.Api_oae_Meeting + 'emeeting/Get_AllPlaceMeeting').toPromise();
    }
    GetAllTypeMeeting(): Promise<any> {
        return this.http.get(this.Api_oae_Meeting + 'emeeting/Get_AllTypeMeeting').toPromise();
    }
    GetAllStatusMeeting(): Promise<any> {
        return this.http.get(this.Api_oae_Meeting + 'emeeting/Get_AllStatusMeeting').toPromise();
    }

    CheckAudiVisualMeetingRoom(data: any) {
        return this.http.get(this.API_SERVER_ADDRESS + 'drt-MeetingRoom/MR-CheckAudiVisualMeetingRoom',data).toPromise();
    }

}